import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBack";
import * as styles from "./back-button.module.scss";

interface BackButtonProps {
  onClick: () => void;
}

const BackButton: React.FC<BackButtonProps> = ({ onClick }) => {
  return (
    <button className={styles.backButton} onClick={onClick} aria-label="back">
      <ArrowBackIosIcon sx={{ marginRight: "0.5rem" }} />
      <span>back</span>
    </button>
  );
};

export default BackButton;
