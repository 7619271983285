import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import BlockContent from "../components/block-content";
import * as styles from "./onboarding.module.scss";
import BackButton from "../components/back-button/back-button";

export interface AutomaticOnboardingPageModel {
  title: string;
  _rawAutomaticDescription: any;
}

export const query = graphql`
  query AutomaticOnboardingPageQuery {
    site: sanityOnboardingPage {
      _rawAutomaticDescription
      title
    }
  }
`;

const AutomaticOnboardingPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site: AutomaticOnboardingPageModel = (data || {}).site;

  return (
    <Layout>
      <SEO title={`${site.title} - Automatic Onboarding`} />
      <Container role="main">
        <div>
          <BackButton onClick={() => window.history.back()} />
          <h1>{site.title} - Automatic Onboarding</h1>
          <div className={styles.descriptionContainer}>
            <BlockContent blocks={site._rawAutomaticDescription} />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default AutomaticOnboardingPage;
